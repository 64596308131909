import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Project } from '../../features/projects/interfaces/project.interface';
import {
  ActiveDetails,
  Career,
  Contact,
  CustomHttpResponse,
  EmailForm,
  Expertise,
  Leadership,
  News,
  Policy,
  Profile,
  Story,
} from '../../shared/interfaces/shared-interface';

@Injectable()
export class GlobalHttpService {
  private readonly server: string = environment.domain;
  private readonly email_api: string = environment.email_api;

  constructor(private http: HttpClient) {}

  public getTranslation(language: string): Observable<any> {
    return this.http.get<any>(`assets/translations/${language}.json`);
  }

  public sendEmail(emailData: EmailForm): Observable<any> {
    return this.http.post<any>(`${this.email_api}/send-email`, emailData);
  }

  public sendResume(formData: any): Observable<any> {
    return this.http.post<any>(`${this.email_api}/send-resume`, formData);
  }

  public fetchAllProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.server}/projects/all`);
  }

  public fetchAllNews(): Observable<News[]> {
    return this.http.get<News[]>(`${this.server}/news/all`);
  }

  public fetchAllExpertises(): Observable<Expertise[]> {
    return this.http.get<Expertise[]>(`${this.server}/expertise/all`);
  }

  public fetchAllCareers(): Observable<Career[]> {
    return this.http.get<Career[]>(`${this.server}/careers/all`);
  }

  public profile(): Observable<CustomHttpResponse<Profile>> {
    return this.http.get<CustomHttpResponse<Profile>>(
      `${this.server}/user/profile`,
      {}
    );
  }

  public fetchActiveDetails(): Observable<ActiveDetails> {
    return this.http.get<ActiveDetails>(`${this.server}/projects/all/details`);
  }

  public deleteMedia(existMedia: string): Observable<CustomHttpResponse<any>> {
    return this.http.delete<CustomHttpResponse<any>>(
      `${this.server}/projects/delete/media/${existMedia}`
    );
  }

  public createDetails(details: any): Observable<CustomHttpResponse<any>> {
    return this.http.post<CustomHttpResponse<any>>(
      `${this.server}/projects/create-details`,
      details
    );
  }

  public fetchStory(): Observable<Story[]> {
    return this.http.get<Story[]>(`${this.server}/about/story`);
  }

  public fetchLeadership(): Observable<Leadership[]> {
    return this.http.get<any>(`${this.server}/about/leadership`);
  }

  public fetchPolicy(): Observable<Policy[]> {
    return this.http.get<any>(`${this.server}/about/policy`);
  }

  public fetchContacts(): Observable<Contact[]> {
    return this.http.get<any>(`${this.server}/contacts/all`);
  }

  public deleteDetails(
    nameList: string,
    detailsId: number
  ): Observable<CustomHttpResponse<any>> {
    return this.http.delete<CustomHttpResponse<any>>(
      `${this.server}/projects/delete-details/${nameList}/${detailsId}`
    );
  }

  public getVideo(fileName: string): Observable<Blob> {
    return this.http.get(`${this.server}/media/video/${fileName}`, {
      responseType: 'blob',
    });
  }

  // public refreshToken$ = () => <Observable<any>>this.http
  //     .get<any>(`${this.server}/user/refresh/token`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem(Key.REFRESH_TOKEN)}`,
  //       },
  //     })
  //     .pipe(
  //       tap((response: any) => {
  //         localStorage.setItem(Key.TOKEN, response.data.access_token);
  //       })
  //     );
}
