import { ViewportScroller } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DotLottie } from '@lottiefiles/dotlottie-web';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AppState } from './app-state';
import { GlobalStoreService } from './global/services/global-store.service';
import { loadTranslation } from './global/store/global.actions';
import { LANGUAGES } from './shared/constants/constants';
import { Expertise } from './shared/interfaces/shared-interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('canvas', { static: true })
  canvasRef!: ElementRef<HTMLCanvasElement>;

  public languages = LANGUAGES;
  public selectedLanguage = this.languages[0];
  public isAdminActive = false;
  public isLoginActive = false;
  public isAnimationProcessActive = false;
  public isFadeOut = false;
  public isInitSuccessfully = false;
  public expertisesList: string[] = [];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private renderer: Renderer2,
    private globalStore: GlobalStoreService,
    private viewportScroller: ViewportScroller
  ) {}

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.toggleScroll(true);
    setTimeout(() => (this.isFadeOut = true), 3500);
    setTimeout(() => (this.isAnimationProcessActive = true), 3500);
    setTimeout(() => this.toggleScroll(false), 3500);
    setTimeout(() => (this.isInitSuccessfully = true), 1000);
    this.store.dispatch(
      loadTranslation({ language: this.selectedLanguage.value })
    );
    this.globalStore.fetchAllExpertises();
    this.globalStore
      .selectAllExpertises()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((expertises: Expertise[]) => {
        this.expertisesList = expertises.map(
          (expertise: Expertise) => expertise.name
        );
      });
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      const currentUrl = this.router.url;
      if (
        currentUrl === '/admin' ||
        currentUrl === '/admin/profile' ||
        currentUrl === '/admin/expertises' ||
        currentUrl === '/admin/projects' ||
        currentUrl === '/admin/careers' ||
        currentUrl === '/admin/news' ||
        currentUrl === '/admin/home' ||
        currentUrl === '/admin/policy' ||
        currentUrl === '/admin/leadership' ||
        currentUrl === '/admin/story' ||
        currentUrl === '/admin/contacts'
      ) {
        this.isAdminActive = true;
      } else {
        this.isAdminActive = false;
      }

      if (currentUrl === '/login') {
        this.isLoginActive = true;
      } else {
        this.isLoginActive = false;
      }
    });

    const dotLottie = new DotLottie({
      autoplay: true,
      loop: true,
      canvas: this.canvasRef.nativeElement,
      src: '../assets/animations/azone-logo.lottie',
    });
  }

  private toggleScroll(shouldDisable: boolean): void {
    if (shouldDisable) {
      this.renderer.setStyle(document.body, 'overflow', 'hidden');
    } else {
      this.renderer.removeStyle(document.body, 'overflow');
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.toggleScroll(false);
  }
}
