import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { Key } from '../../global/enum/global.enum';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    if (this.shouldBypass(request.url)) {
      return next.handle(request);
    }

    const reqWithCredentials = request.clone({ withCredentials: true });
    const authorizedRequest = this.addAuthorizationTokenHeader(
      reqWithCredentials,
      localStorage.getItem(Key.TOKEN)
    );

    return next.handle(authorizedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          localStorage.removeItem(Key.TOKEN);
          localStorage.removeItem(Key.REFRESH_TOKEN);
          location.reload();
        }
        return throwError(error);
      })
    );
  }

  private shouldBypass(url: string): boolean {
    return (
      url.includes('login') ||
      url.includes('refresh') ||
      url.includes('careers/all') ||
      url.includes('expertise/all') ||
      url.includes('projects/all') ||
      url.includes('contacts/all') ||
      url.includes('news/all') ||
      (url.includes('api/about/story') &&
        !url.includes('api/about/story/update') &&
        !url.includes('api/about/story/update/file') &&
        !url.includes('api/about/story/delete/story-part') &&
        !url.includes('api/about/story/create/story-part')) ||
      (url.includes('api/about/leadership') &&
        !url.includes('api/about/leadership/update') &&
        !url.includes('api/about/leadership/update/file') &&
        !url.includes('api/about/leadership/delete/leadership-part') &&
        !url.includes('api/about/leadership/create/leadership-part')) ||
      (url.includes('api/about/policy') &&
        !url.includes('api/about/policy/update') &&
        !url.includes('api/about/policy/update/file') &&
        !url.includes('api/about/policy/delete/policy-part') &&
        !url.includes('api/about/policy/create/policy-part')) ||
      url.includes('send-email') ||
      url.includes('send-resume') ||
      url.includes('media/video') ||
      (url.includes('projects/all/details') &&
        url.includes('projects/create-details') &&
        url.includes('projects/delete-details'))
    );
  }

  private addAuthorizationTokenHeader(
    request: HttpRequest<unknown>,
    token: string | null
  ): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }
}
