import { createReducer, on } from '@ngrx/store';
import { Project } from '../../features/projects/interfaces/project.interface';
import {
  ActiveDetails,
  Career,
  Contact,
  Expertise,
  News,
  Story,
  User,
} from '../../shared/interfaces/shared-interface';
import * as GlobalActions from './global.actions';

export interface GlobalState {
  profile: User | any[];
  emailLoading: boolean;
  translations: any;
  projectsList: Project[];
  expertisesList: Expertise[];
  newsList: News[];
  contactsList: Contact[];
  activeDetails: ActiveDetails;
  careers: Career[];
  isLoading: boolean;
  isLoadingProjectMediaDelete: boolean;
  video: Blob | null;
  story: Story | any;
  policy: any;
  leadership: any;
  error: Error | null;
}

const initialState: GlobalState = {
  activeDetails: {
    locationList: [],
    typeList: [],
    expertiseList: [],
    categoryList: [],
    departmentList: [],
    videoHome: null,
  },
  video: null,
  newsList: [],
  contactsList: [],
  profile: [],
  emailLoading: false,
  translations: {},
  projectsList: [],
  expertisesList: [],
  careers: [],
  isLoading: false,
  isLoadingProjectMediaDelete: false,
  story: [],
  policy: [],
  leadership: [],
  error: null,
};

export const globalReducer = createReducer(
  initialState,
  // Fetch Profile
  on(GlobalActions.fetchProfile, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(GlobalActions.fetchProfileSuccess, (state, { payload }) => ({
    ...state,
    profile: payload.data,
    loading: false,
    error: null,
  })),
  on(GlobalActions.fetchProfileError, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  // Load Translation
  on(GlobalActions.loadTranslation, (state, { language }) => ({
    ...state,
    currentLanguage: language,
  })),
  on(GlobalActions.loadTranslationSuccess, (state, { data }) => ({
    ...state,
    translations: data,
  })),
  // Send Email
  on(GlobalActions.sendEmail, (state) => ({
    ...state,
    emailLoading: true,
  })),
  on(GlobalActions.sendEmailSuccess, (state) => ({
    ...state,
    emailLoading: false,
  })),
  on(GlobalActions.sendEmailError, (state) => ({
    ...state,
    emailLoading: false,
  })),
  // Send Resume
  on(GlobalActions.sendResume, (state) => ({
    ...state,
    emailLoading: true,
  })),
  on(GlobalActions.sendResumeSuccess, (state) => ({
    ...state,
    emailLoading: false,
  })),
  on(GlobalActions.sendResumeError, (state) => ({
    ...state,
    emailLoading: false,
  })),
  // Fetch Projects
  on(GlobalActions.fetchAllProjects, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(GlobalActions.fetchAllProjectsSuccess, (state, { response }) => ({
    ...state,
    isLoading: false,
    projectsList: response,
  })),
  on(GlobalActions.fetchAllProjectsError, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  // Fetch Expertises
  on(GlobalActions.fetchAllExpertises, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(GlobalActions.fetchAllExpertisesSuccess, (state, { response }) => ({
    ...state,
    isLoading: false,
    expertisesList: response,
  })),
  on(GlobalActions.fetchAllExpertisesError, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  // Fetch Active Details
  on(GlobalActions.fetchActiveDetails, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(GlobalActions.fetchActiveDetailsSuccess, (state, { response }) => ({
    ...state,
    isLoading: false,
    activeDetails: response,
  })),
  on(GlobalActions.fetchActiveDetailsError, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  // Delete Project Media
  on(GlobalActions.deleteMedia, (state) => ({
    ...state,
    isLoadingProjectMediaDelete: true,
  })),
  on(GlobalActions.deleteMediaSuccess, (state) => ({
    ...state,
    isLoadingProjectMediaDelete: false,
  })),
  on(GlobalActions.deleteMediaError, (state, action) => ({
    ...state,
    isLoadingProjectMediaDelete: false,
    error: action.error,
  })),
  // Fetch All News
  on(GlobalActions.fetchAllNews, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(GlobalActions.fetchAllNewsSuccess, (state, { response }) => ({
    ...state,
    isLoading: false,
    newsList: response,
  })),
  on(GlobalActions.fetchAllNewsError, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  // Fetch All Careers
  on(GlobalActions.fetchAllCareers, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(GlobalActions.fetchAllCareersSuccess, (state, { response }) => ({
    ...state,
    careers: response,
    isLoading: false,
  })),
  on(GlobalActions.fetchAllCareersError, (state, action) => ({
    ...state,
    error: action.err,
    isLoading: false,
  })),
  // Fetch Story
  on(GlobalActions.fetchStory, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(GlobalActions.fetchStorySuccess, (state, { response }) => ({
    ...state,
    story: response[0],
    isLoading: false,
  })),
  on(GlobalActions.fetchStoryError, (state, action) => ({
    ...state,
    error: action.error,
    isLoading: false,
  })),
  // Fetch Leadership
  on(GlobalActions.fetchLeadership, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(GlobalActions.fetchLeadershipSuccess, (state, { response }) => ({
    ...state,
    leadership: response[0],
    isLoading: false,
  })),
  on(GlobalActions.fetchLeadershipError, (state, action) => ({
    ...state,
    error: action.error,
    isLoading: false,
  })),
  // Fetch Policy
  on(GlobalActions.fetchPolicy, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(GlobalActions.fetchPolicySuccess, (state, { response }) => ({
    ...state,
    policy: response[0],
    isLoading: false,
  })),
  on(GlobalActions.fetchPolicyError, (state, action) => ({
    ...state,
    error: action.error,
    isLoading: false,
  })),
  // Delete Details
  on(GlobalActions.deleteDetails, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(GlobalActions.deleteDetailsSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(GlobalActions.deleteDetailsError, (state, { error }) => ({
    ...state,
    isLoading: false,
    error: error,
  })),
  // Fetch All Contacts
  on(GlobalActions.fetchContacts, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(GlobalActions.fetchContactsSuccess, (state, { response }) => ({
    ...state,
    contactsList: response,
    isLoading: false,
  })),
  on(GlobalActions.fetchContactsError, (state, { error }) => ({
    ...state,
    isLoading: false,
    error: error,
  })),
  // Create Details
  on(GlobalActions.createDetails, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(GlobalActions.createDetailsSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(GlobalActions.createDetailsError, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  on(GlobalActions.loadVideoSuccess, (state, { video }) => ({
    ...state,
    video,
    error: null,
  })),
  on(GlobalActions.loadVideoFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);
